export default {
  expertise: {
    preTitle: 'Expertise',
    title: 'Imagine the magic',
  },
  manifesto: {
    preTitle: 'Manifesto',
    title: 'B.Lieve',
    text: 'Behind the 3D, there is a world.<br/> Actually, a journey.',
    cta: 'Our manifesto',
  },
  projects: {
    preTitle: 'Works highlights',
    title: 'Magic at work',
    allProjects: 'All our works',
  },
  technology: {
    preTitle: 'Technology',
  },
  blog: {
    allBlogs: 'All articles',
  },
}
