export default {
  404: {
    title: 'Pagina non trovata',
    text: 'La pagina che cerchi non è disponibile.<br /> Prova a tornare alla homepage o riprova più tardi',
    content: {
      title: 'Sembra che la pagina che stavi cercando non esista più o sia stata spostata.<br>Non preoccuparti, ti aiutiamo a ritrovare la strada.',
      pre: 'Ecco alcune cose che puoi fare:',
      home: {
        label: 'Torna alla Homepage',
        text: ': inizia da capo e scopri tutte le sezioni del nostro sito.'
      },
      contact: {
        label: 'Contattaci',
        text: ': se hai bisogno di assistenza, non esitare a contattarci.'
      },
      expertise: {
        label: 'Scopri le nostre Expertise',
        text: ': il mondo 3D può rivoluzionare la tua comunicazione.'
      },
      blog: {
        label: 'Leggi i nostri articoli',
        text: ': novità e curiosità dal mondo delle 3 dimensioni.'
      },
      studio: {
        label: 'Lo studio',
        text: ': scopri chi siamo e la nostra filosofia.'
      }
    }
  },
  500: {
    title: 'Errore interno del server',
    text: 'Spiacenti, si è verificato un errore interno. <br />Stiamo lavorando per risolvere il problema il prima possibile.',
  },
}
