export default {
  'menu-nav': {
    menu: 'Menu',
    works: 'Works',
    expertise: 'Expertise',
    expertises: 'All expertises',
    technology: 'Technology',
    technologies: 'All technologies',
    studio: 'Studio',
    blog: 'B.log',
    contacts: 'Contact',
    back: 'Back',
    /*links: [
      {
        label: 'Works',
        route: { name: 'projects' }
      },
      {
        label: 'Expertise',
        route: { name: 'expertise' }
      },
      {
        label: 'Technology',
        route: { name: 'technology' }
      },
      {
        label: 'Studio',
        route: { name: 'studio' }
      },
      {
        label: 'B.log',
        route: { name: 'blog' }
      },
      {
        label: 'Contact',
        route: { name: 'contacts' }
      },
    ]*/
  },
}
