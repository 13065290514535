export default {
  expertise: {
    preTitle: 'Expertise',
    title: 'Imagine the magic',
  },
  manifesto: {
    preTitle: 'Manifesto',
    title: 'B.Lieve',
    text: 'Dietro il 3D c’è un mondo.<br/> Anzi un viaggio.',
    cta: 'Il nostro manifesto',
  },
  projects: {
    preTitle: 'Works highlights',
    title: 'Magic at work',
    allProjects: 'Tutti i nostri lavori',
  },
  technology: {
    preTitle: 'Technology',
  },
  blog: {
    allBlogs: 'Tutti gli articoli',
  },
}
