export default {
  form: {
    name: 'Name*',
    surname: 'Surname*',
    email: 'E-mail*',
    company: 'Company*',
    agency: 'Agency*',
    phone: 'Telephone',
    portfolio: 'Portfolio Link',
    portfolioOptional: 'Other Link',
    fileUpload: 'Resume (PDF)*',
    fileUploadTxt: 'UPLOAD YOUR RESUME',
    presentation: 'Presentation Text',
message: 'Describe your project / request*',
privacyCaption:
  'Pursuant to and for the purposes of Reg. EU 2016/679 - GDPR, I give consent to the processing of my personal data as per the <a href="/privacy-policy" target="_blank" class="link-underline-1">privacy policy</a>*',
newsletterCaption: 'Send me updates on the magic of CGI',
submitCTA: 'SUBMIT REQUEST',
genericError: 'Field not filled out correctly',
successPageTitle: 'Thank you',
successPageText:
  'The form has been successfully submitted, and you will be contacted by the team as soon as possible.',
failurePageTitle: 'We are sorry',
failurePageText:
  'There were errors in submitting the form. Please try again later.',
},
careers: {
  formCTA: 'Submit your application',
  formSubtitle: '<strong>Requirements and skills</strong>',
  formCaption:
    'If you think you are the right person for us, fill out the form and submit your application.',
  otherPositionsTitle: 'Other open positions',
  postinApiId: 'e2209090-6264-4d73-803b-dd85af69e909',
},
seoSuccess: {
  title: 'Request submitted',
},
seoFailure: {
  title: 'Error!',
},
newsletter: {
  successTitle: 'Welcome to our newsletter!',
  successText:
    'From today, you will be among the first to discover what’s behind the magic of Bluemotion.',
  seo: {
    title: 'Newsletter subscription completed',
  },
  },
}
