export default {
  main: {
    knowUs:
      'Get in touch: together we can go further and create new magical experience.',
    discover: 'Join our team and discover the open positions.',
    newsletter: 'Sign up to receive the latest magic from Bluemotion.',
    emailField: 'Email',
    newsletterCaption:
      'Pursuant to and for the purposes of Reg. EU 2016/679 - GDPR, I give consent to the processing of my personal data as per the <a href="/privacy-policy" target="_blank" class="link-underline-1">privacy policy</a>',
    corporate:
      '<strong>Bluemotion Srl</strong> Viale del Lavoro 14, int 34, 35020 Ponte San Nicolò, Padova VAT 03897210286',
    associated: 'Affiliation',
    copyright:
      '<span class="show-for-large">Copyright</span>© Bluemotion {year}',
    privacy: 'Privacy Policy',
    mail: 'contact@bluemotion.it',
    mailLink: 'mailto:contact@bluemotion.it',
  },
  medical: {
    title: 'BLUEMOTION MEDICAL',
    textBlock:
      'Complexity meets clarity. Taking medical communication to a new dimension.',
    dedicatedCta: 'VISIT THE DEDICATED SITE',
    url: 'https://bluemotionmedical.com/en',
  },
}
