export default {
  tags: {
    industry: 'Industry',
    technology: 'Technology',
  },
    noProject: 'There are no projects matching the applied filters.',
    otherProjects: 'Other projects you might be interested in',
    relatedProjects: '{tech} projects',
    allTechnologiesTitle: 'Bluemotion 3D technologies',
}
