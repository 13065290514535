export default {
  tags: {
    industry: 'Industry',
    technology: 'Technology',
  },
  noProject: 'Non ci sono progetti che corrispondono ai filtri applicati.',
  otherProjects: 'Altri progetti che potrebbero interessarti',
  relatedProjects: 'Progetti {tech}',
  allTechnologiesTitle: 'Le tecnologie 3D Bluemotion',
}
