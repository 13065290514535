export default {
  main: {
    knowUs:
      'Conosciamoci: insieme possiamo arrivare più lontano di quanto immagini.',
    discover:
      'Scopri le posizioni aperte per mettere alla prova il tuo talento.',
    newsletter: 'Scopri cosa c’è dietro la magia di Bluemotion.',
    emailField: 'Email',
    newsletterCaption:
      'Ai sensi e per gli effetti del Reg. UE 2016/679 - GDPR presto il consenso al trattamento dei miei dati personali come da <a href="/it/privacy-policy" class="link-underline-1">informativa sulla privacy</a>',
    newsletterError:
      'Si prega di accettare il trattamento dei dati conforme al GDPR',
    corporate:
      '<strong>Bluemotion Srl</strong> Viale del Lavoro 14, int 34, 35020 Ponte San Nicolò, Padova P.iva 03897210286',
    associated: 'Siamo associati',
    copyright:
      '<span class="show-for-large">Copyright</span>© Bluemotion {year}',
    privacy: 'Privacy Policy',
    mail: 'contatto@bluemotion.it',
    mailLink: 'mailto:contatto@bluemotion.it',
  },
  medical: {
    title: 'BLUEMOTION MEDICAL',
    textBlock:
      'La complessità incontra la chiarezza. Porta la comunicazione medicale a una nuova dimensione.',
    dedicatedCta: 'VISITA IL SITO DEDICATO',
    url: 'https://bluemotionmedical.com/',
  },
}
