import errors from './errors.js'
import cta from './cta.js'
import home from './home.js'
import projects from './projects.js'
import menu from './menu.js'
import contacts from './contacts.js'
import footer from './footer.js'
import blog from './blog.js'

export default {
  errors,
  cta,
  home,
  projects,
  menu,
  footer,
  contacts,
  blog,
}
