export default {
  close: 'Chiudi',
  playReel: 'Play Reel',
  playVideo: 'Play Video',
  quickView: 'Quick View',
  contactBanner:
    'Facciamo <strong>succedere</strong><br /> qualcosa di <strong>magico</strong>, insieme.',
  contactUs: 'Contattaci',
  projectDetails: 'Work info',
  discoverProject: 'Discover project',
  projectsFilters: 'Filtra',
  workWithUs: 'Lavora con noi',
  subscribe: 'Iscriviti',
  readBlog: "Leggi l'articolo",
  rotateDevice: 'Ruota il dispositivo',
  scrollToTop: 'Torna in cima alla pagina',
  backToHome: 'Torna in homepage',
  skip: 'Skip',
  'click-voice-form': 'Clicca dove vuoi per iniziare!',
}
