export default {
  close: 'Chiudi',
  contactUs: 'Contact us',
  playReel: 'Play Reel',
  playVideo: 'Play Video',
  quickView: 'Quick View',
  contactBanner:
    'Facciamo <strong>succedere</strong><br /> qualcosa di <strong>magico</strong>, insieme.',
  workWithUs: 'Careers',
  subscribe: 'Subscribe',
  skip: 'Skip',
  'click-voice-form': 'Click anywhere to start!',
  projectDetails: 'Work info',
  discoverProject: 'Discover project',
  projectsFilters: 'Filter',
  readBlog: "Read the article",
  rotateDevice: 'Rotate your device',
  scrollToTop: 'Back to top',
  backToHome: 'Back to homepage',
}
